import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Breadcrumb,
  Card,
} from '@themesberg/react-bootstrap'
import { useLocation } from 'react-router-dom'
import api from '../services/api'
import apilocal from '../services/apilocal'

import '../assets/styles/CheckoutForm.css'

export const Success = ({ name }) => {
  return (
    <Card.Body className="p-5">
      <Row className="justify-content-center">
        <Col xs={12} md={12} className="text-center">
          <div className="alert alert-success pt-5" role="alert">
            <h1 style={{ color: '#0f5132' }}>
              Your ad has been published successfully!
            </h1>
            <p className="mt-3 mb-5">Wait a moment...</p>
          </div>
        </Col>
      </Row>
    </Card.Body>
  )
}

export default ({ name }) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  let query = useQuery()
  const category = query.get('name')
  console.log(query.get('skuAd'))
  console.log(query.get('category'))
  const info = JSON.parse(window.sessionStorage.getItem('userData'))
  useEffect(() => {
    const config = {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + info.token,
      },
    }
    apilocal
      .put(
        `https://develop.jaspiapp.com/${query.get(
          'category'
        )}/${query.get('skuAd')}`,
        { status: 'active' },
        config
      )
      .then((response) => {
        console.log(response.data)
        setTimeout(() => {
          window.location.href =
            `http://dash.jaspiapp.com/#/ads?name=${query.get('category')}`
          
        }, 5000)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [info])

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>JASPI</Breadcrumb.Item>
            <Breadcrumb.Item active>New ad</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <Success />
    </>
  )
}
