import React from 'react'
import SimpleBar from 'simplebar-react'
import { useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBullhorn,
  faSignOutAlt,
  faTimes,
  faHouseUser,
  faHome,
  faConciergeBell,
  faCar,
  faUtensils,
  faBriefcase,
  faCouch,
  faNewspaper,
  faPaperclip,
} from '@fortawesome/free-solid-svg-icons'
import {
  Nav,
  Badge,
  Image,
  Button,
  Accordion,
  Navbar,
} from '@themesberg/react-bootstrap'
import { Link } from 'react-router-dom'

import { Routes } from '../routes'
import ReactHero from '../assets/img/logo.png'
import ProfilePicture from '../assets/img/team/profile-picture-3.jpg'

export default (props = {}) => {
  const location = useLocation()
  const { pathname } = location
  const [show, setShow] = React.useState(false)
  const showClass = show ? 'show' : ''

  const onCollapse = () => setShow(!show)

  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : ''

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button
            as={Nav.Link}
            className="d-flex justify-content-between align-items-center"
          >
            <span>
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{' '}
              </span>
              <span className="sidebar-text me-2">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">{children}</Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    )
  }

  const NavItem = (props) => {
    const {
      title,
      link,
      external,
      target,
      icon,
      image,
      badgeText,
      badgeBg = 'secondary',
      badgeColor = 'primary',
    } = props
    const classNames = badgeText
      ? 'd-flex justify-content-start align-items-center justify-content-between'
      : ''
    const navItemClassName = link === pathname ? 'active' : ''
    const linkProps = external ? { href: link } : { as: Link, to: link }

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{' '}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge
              pill
              bg={badgeBg}
              text={badgeColor}
              className="badge-md notification-count ms-2"
            >
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    )
  }

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={ProfilePicture}
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="xs"
                    to={Routes.Signin.path}
                    className="text-dark"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{' '}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              {/* <NavItem title="Home" link={Routes.DashboardOverview.path} image={ReactHero} /> */}
              <CollapsableNavItem
                eventKey="/"
                title="My Ads"
                icon={faPaperclip}
              >
                <NavItem
                  title="Ads"
                  link={Routes.ListAnnouncements.path + '?name=announcement'}
                  icon={faNewspaper}
                />
              </CollapsableNavItem>
              {/* <CollapsableNavItem
                eventKey="/"
                title="My properties"
                icon={faHouseUser}
              >
                <NavItem
                  title="Full Houses"
                  link={Routes.ListAds.path + '?name=fullhouse'}
                  icon={faHome}
                />
                <NavItem
                  title="Shared Rooms"
                  link={Routes.ListAds.path + '?name=sharedroom'}
                  icon={faHome}
                />
                <NavItem
                  title="Private Rooms"
                  link={Routes.ListAds.path + '?name=privateroom'}
                  icon={faHome}
                />
              </CollapsableNavItem>
              <CollapsableNavItem eventKey="/" title="My Ads" icon={faBullhorn}>
                <NavItem
                  title="Services"
                  link={Routes.ListAds.path + '?name=service'}
                  icon={faConciergeBell}
                />
                <NavItem
                  title="Products"
                  link={Routes.ListAds.path + '?name=product'}
                  icon={faCouch}
                />
                <NavItem
                  title="Jobs"
                  link={Routes.ListAds.path + '?name=job'}
                  icon={faBriefcase}
                />
                <NavItem
                  title="Motors"
                  link={Routes.ListAds.path + '?name=motor'}
                  icon={faCar}
                />
                <NavItem
                  title="Foods & Drinks"
                  link={Routes.ListAds.path + '?name=art'}
                  icon={faUtensils}
                />
              </CollapsableNavItem> */}

              {/* <NavItem title="Payment Method" link={Routes.Billing.path} icon={faCreditCard}/> */}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  )
}
