import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
} from '@themesberg/react-bootstrap'
import { Link } from 'react-router-dom'
import api from '../../services/api'
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete'
import toast, { Toaster } from 'react-hot-toast'
import { useLocation, useHistory } from 'react-router-dom'
import { app } from '../../services/firebase'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'

import { Routes } from '../../routes'
import BgImage from '../../assets/img/bgLogin.jpg'

export default () => {
  const auth = getAuth(app)
  let history = useHistory()
  const [name, setname] = useState('')
  const [surname, setsurname] = useState('')
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [about, setabout] = useState('Buyer')
  const [pass, setpass] = useState('')
  const [confpass, setconfpass] = useState('')
  const [localization, setlocalization] = React.useState('')
  const [localizationLat, setlocalizationLat] = React.useState('')
  const [localizationLng, setlocalizationLng] = React.useState('')
  const [localizationCountry, setlocalizationCountry] = React.useState('UK')

  const firebaseAuth = async () => {
    let resp = false
    await createUserWithEmailAndPassword(auth, email, pass)
      .then((userCredential) => {
        // console.log(userCredential.user)
        return (resp = true)
      })
      .catch((error) => {
        resp = false
        return toast.error(error.message)
      })
    return resp
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (pass !== confpass) {
      toast.error('Passwords entered must be the same')
    } else {
      var formData = new FormData()

      formData.append('name', name)
      formData.append('surname', surname)
      formData.append('email', email)
      formData.append('phone', phone)
      formData.append('password', pass)

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      const auth = await firebaseAuth()
      if (auth) {
        await api
          .post('https://develop.jaspiapp.com/register', formData, config)
          .then(async (response) => {
            if (response.data.success === true) {
              toast.success('Account created successfully!')
              setTimeout(() => {
                history.push('/announcements?name=announcement')
              }, 2000)
            }
          })
          .catch((err) => {
            console.log(err)
            toast.error(err.response.message)
          })
      } else {
        return
      }
    }
  }

  return (
    <main style={{ backgroundImage: `url(${BgImage})` }}>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Toaster
            position="top-right"
            toastOptions={{
              className: '',
              duration: 5000,
              style: {
                background: '#363636',
                color: '#fff',
              },
              success: {
                duration: 5000,
                theme: {
                  primary: 'green',
                  secondary: 'black',
                },
              },
            }}
          />

          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="name" className="mb-4">
                    <Form.Label>First name</Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        type="text"
                        name={'name'}
                        onInput={(e) => setname(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="surname" className="mb-4">
                    <Form.Label>Surname</Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        type=""
                        placeholder=""
                        name={'surname'}
                        onInput={(e) => setsurname(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        type="email"
                        name={'email'}
                        onInput={(e) => setemail(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="phone" className="mb-4">
                    <Form.Label>Phone</Form.Label>
                    <InputGroup>
                      <Form.Control
                        required
                        type=""
                        placeholder=""
                        name={'phone'}
                        onInput={(e) => setphone(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  {/* <Form.Group id="address" className="mb-4">
                    <Form.Label>Address</Form.Label>

                    <GooglePlacesAutocomplete
                      apiKey="AIzaSyAoUcCwPuCITViMMwEdieJdQ3R8Gh950-c"
                      name={'localization'}
                      placeholder=""
                      selectProps={{
                        onChange: (value) =>
                          geocodeByAddress(value.value.description)
                            .then((results) => getLatLng(results[0]))
                            .then(
                              ({ lat, lng }) => (
                                setlocalization(value.value.description),
                                setlocalizationLat(lat),
                                setlocalizationLng(lng)
                              )
                            ),
                      }}
                    />
                  </Form.Group> */}
                  {/* <Form.Group id="about" className="mb-4">
                    <Form.Label>About me</Form.Label>
                    <Form.Select
                      name={'about'}
                      onChange={(e) => setabout(e.target.value)}
                    >
                      <option value="Buyer">Buyer</option>
                      <option value="Seller">Seller</option>
                      <option value="Buyer and Seller">Buyer and Seller</option>
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        name={'pass'}
                        onInput={(e) => setpass(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        type="password"
                        name={'confpass'}
                        onInput={(e) => setconfpass(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>
                  <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      I agree to the <Card.Link>terms and conditions</Card.Link>
                    </FormCheck.Label>
                  </FormCheck>

                  <Button variant="primary" type="submit" className="w-100">
                    Sign up
                  </Button>
                </Form>

                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pill text-twitter me-2">
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button variant="outline-light" className="btn-icon-only btn-pil text-dark">
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div> */}
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link
                      as={Link}
                      to={Routes.Signin.path}
                      className="fw-bold"
                    >
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
