import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisH,
  faEye,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { Button, ButtonGroup, Dropdown } from '@themesberg/react-bootstrap'

import Moment from 'react-moment'
import api from '../../services/api'
import toast from 'react-hot-toast'

const TableRow = (props, category) => {
  const deleteAds = async (id) => {
    let info = JSON.parse(window.sessionStorage.getItem('userData'))
    await api
      .delete(`/announcement/${id}`, {
        headers: { Authorization: 'Bearer ' + info.token },
      })
      .then(function (response) {
        toast.success('Your ad has been disabled')
        props.getAds(info.token)
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response.data.message)
      })
  }

  const { status } = props
  const statusVariant =
    status === 'active'
      ? 'success'
      : status === 'Due'
      ? 'warning'
      : status === 'inative'
      ? 'danger'
      : 'primary'

  return (
    <tr>
      <td className="px-0 mx-0">
        <span
          title={props.title}
          className="fw-normal"
          style={{
            maxWidth: '300px',
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {props.title}
        </span>
      </td>
      <td>
        <span className="fw-normal">
          {props.createdAt ? (
            <Moment format="YY / MM / DD">{props.createdAt}</Moment>
          ) : (
            '-'
          )}
        </span>
      </td>
      <td>
        <span className={`fw-normal text-${statusVariant}`}>
          {props.status}
        </span>
      </td>
      <td>
        <span className={`fw-normal `}>
          {props.adCategory ? props.adCategory?.title?.en : '-'}
        </span>
      </td>
      <td>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            as={Button}
            split
            variant="link"
            className="text-dark m-0 p-0"
          >
            <span className="icon icon-sm">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() =>
                window.open(
                  `https://www.jaspiapp.com/details/${props._id}`,
                  '_blank'
                )
              }
            >
              <FontAwesomeIcon icon={faEye} className="me-2" /> View
            </Dropdown.Item>
            {/* <Dropdown.Item>
              <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
            </Dropdown.Item> */}
            <Dropdown.Item
              className="text-danger"
              onClick={() => deleteAds(props._id)}
            >
              <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Disable
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  )
}

export default TableRow
