import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  Button,
  Breadcrumb,
  InputGroup,
  Card,
  Table,
  Pagination,
  Nav,
} from '@themesberg/react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import api from '../services/api';
import toast, { Toaster } from 'react-hot-toast';
import apilocal from '../services/apilocal';
import TableRow from './components/TableRow';

const useQuery = () => new URLSearchParams(useLocation().search);

const AnnouncementsList = ({ search }) => {
  const query = useQuery();
  const category = query.get('name');

  const [totalTransactions, setTotalTransactions] = useState(0);
  const [atualPage, setAtualPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [ads, setAds] = useState([]);
  const [token, setToken] = useState('');
  const [userInfo, setUserInfo] = useState('');
  const [pages, setPages] = useState([]);

  const getUser = () => {
    try {
      let info = JSON.parse(window.sessionStorage.getItem('userData'));
      if (!info) {
        window.location.href = 'http://dash.jaspiapp.com/';
      } else {
        setUserInfo(info);
        setToken(info.token);
        getAds(info);
      }
    } catch (error) {
      console.error('Failed to get user data:', error);
      toast.error('Failed to get user data.');
    }
  };

  const getAds = async (user, search = '', page = 1) => {
    try {
      const response = await api.get(
        `/announcement?paginate=true&search=${search}&limit=10&page=${page}&user=${user.user._id}`,
        {
          headers: { Authorization: 'Bearer ' + user.token },
        }
      );
      const retorno = response.data.docs;
      const adsReturn = Object.keys(retorno).map((i) => retorno[Number(i)]);
      setAds(adsReturn);
      setTotalTransactions(response.data.docs.length);
      setTotalPages(response.data.pages);
      const array = Array.from({ length: response.data.pages }, (_, i) => i + 1);
      setPages(array);
    } catch (error) {
      console.error('Failed to get ads:', error);
      toast.error('Failed to get ads.');
    }
  };

  const prevButton = () => {
    if (atualPage !== 1) {
      setAtualPage(atualPage - 1);
    }
  };

  const nextButton = () => {
    if (atualPage !== totalPages) {
      setAtualPage(atualPage + 1);
    }
  };

  const changePage = (value) => {
    setAtualPage(value);
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (userInfo) {
      getAds(userInfo, search, atualPage);
    }
  }, [search, atualPage, userInfo]);

  return (
    <>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Toaster
          position="top-right"
          toastOptions={{
            className: '',
            duration: 5000,
            style: {
              background: '#363636',
              color: '#fff',
            },
            success: {
              duration: 5000,
              theme: {
                primary: 'green',
                secondary: 'black',
              },
            },
          }}
        />
        <Card.Body className="pt-0">
          <Table hover className="user-table align-items-center">
            <thead>
              <tr>
                <th className="border-bottom">Title</th>
                <th className="border-bottom">Created in</th>
                <th className="border-bottom">Status</th>
                <th className="border-bottom">Category</th>
                <th className="border-bottom">Action</th>
              </tr>
            </thead>
            <tbody>
              {ads.length ? (
                ads.map((t) => (
                  <TableRow
                    key={`transaction-${t._id}`}
                    {...t}
                    category={category}
                    getAds={getAds}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">No ads found.</td>
                </tr>
              )}
            </tbody>
          </Table>
          {ads.length ? (
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
              <Nav>
                <Pagination className="mb-2 mb-lg-0">
                  <Pagination.Prev onClick={prevButton}>Previous</Pagination.Prev>
                  {pages.map((page) => (
                    <Pagination.Item
                      key={page}
                      onClick={() => changePage(page)}
                      active={page === atualPage}
                    >
                      {page}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next onClick={nextButton}>Next</Pagination.Next>
                </Pagination>
              </Nav>
              <small className="fw-bold">
                Showing <b>{atualPage}</b> of <b>{totalPages}</b> pages
              </small>
            </Card.Footer>
          ) : null}
        </Card.Body>
      </Card>
    </>
  );
};

const AnnouncementsPage = ({ name }) => {
  const history = useHistory();
  const [showDefault, setShowDefault] = useState(false);
  const [search, setSearch] = useState('');

  const handleClose = () => setShowDefault(false);
  const query = useQuery();
  const urlAd = './add-announcement?name=announcement';

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>JASPI</Breadcrumb.Item>
            <Breadcrumb.Item active>My Ads</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Ads</h4>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
          <Col xs={8} md={8} lg={10} xl={10}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col xs={4} md={4} lg={2} xl={2} className="text-right">
            <Button
              variant="primary"
              size="md"
              onClick={() => history.push(urlAd)}
            >
              + New
            </Button>
          </Col>
        </Row>
      </div>

      <AnnouncementsList search={search} />
    </>
  );
};

export default AnnouncementsPage;