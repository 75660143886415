import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from '@themesberg/react-bootstrap'
import { Link } from 'react-router-dom'

import { Routes } from '../../routes'
import { useState } from 'react'
import { app } from '../../services/firebase'
import { sendPasswordResetEmail, getAuth } from 'firebase/auth'
import toast, { Toaster } from 'react-hot-toast'

export default () => {
  const auth = getAuth(app)

  const [email, setEmail] = useState('')

  async function resetEmail(e) {
    e.preventDefault()
    if (!email) {
      toast.error('Please, send a email')
    }
    await sendPasswordResetEmail(auth, email)
      .then(function () {
        // Password reset email sent successfully
        toast.success('Password reset email sent')
      })
      .catch(function (error) {
        toast.error('Error sending password reset email: ', error.message)
      })
  }

  return (
    <main>
      <Toaster
        position="top-right"
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 5000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link
                as={Link}
                to={Routes.Signin.path}
                className="text-gray-700"
              >
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
                sign in
              </Card.Link>
            </p>
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">
                  Don't fret! Just type in your email and we will send you a
                  code to reset your password!
                </p>
                <Form onSubmit={(e) => resetEmail(e)}>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                    <InputGroup id="email">
                      <Form.Control
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoFocus
                        type="email"
                        placeholder="your@email.com"
                      />
                    </InputGroup>
                  </div>
                  <Button variant="primary" type="submit" className="w-100">
                    Recover password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
