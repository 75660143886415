import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Form,
  Button,
  Breadcrumb,
  Card,
  Modal,
} from '@themesberg/react-bootstrap'
import { useLocation } from 'react-router-dom'
import api from '../services/api'
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete'
import CurrencyInput from 'react-currency-input-field'
import toast, { Toaster } from 'react-hot-toast'
import { loadStripe } from '@stripe/stripe-js'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const options = [
  {
    country: 'Pound',
    locale: 'en-GB',
    currency: 'GBP',
  },
  {
    country: 'Euro',
    locale: 'de-DE',
    currency: 'EUR',
  },
  {
    country: 'Dollar',
    locale: 'en-US',
    currency: 'USD',
  },

  {
    country: 'Real',
    locale: 'pt-BR',
    currency: 'BRL',
  },
]

export const AnnouncementAdd = ({ name }) => {
  const stripePromise = loadStripe(
    'pk_live_51JxDBnJ6bCi1hLHkX5ZLMo9FXM3xNuzu6uAxqh1mn6J5oKspBAarcoPpdohpoAYycyE1OwfNxE8EThi92jgEm6Ed005ECD31Ny'
  )

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  let query = useQuery()
  const category = query.get('name')

  const [totalTransactions, setTotalTransactions] = React.useState(0)
  const [ads, setAds] = React.useState([])
  const [token, setToken] = React.useState('')
  const [userInfo, setUserInfo] = React.useState('')
  const [idCategory, setidCategory] = React.useState('')
  const [idSubCategory, setidSubCategory] = React.useState('')
  const [showDefault, setShowDefault] = useState(false)
  const handleClose = () => setShowDefault(false)

  //* Ad Fields form
  const [catProperty, setcatProperty] = React.useState([])
  const [catSubProperty, setcatSubProperty] = React.useState([])
  const [title, settitle] = React.useState('')
  const [price, setprice] = React.useState(0.0)
  const [currencyCode, setCurrencyCode] = useState('GBP')
  const [currency, setCurrency] = React.useState('£')
  const [ecommerce, setEcommerce] = React.useState('')
  const [whatsapp, setWhatsapp] = React.useState('')
  const [localization, setlocalization] = React.useState('')
  const [description, setdescription] = React.useState('')

  const [localizationLat, setlocalizationLat] = React.useState('')
  const [localizationLng, setlocalizationLng] = React.useState('')
  const [localizationCountry, setlocalizationCountry] = React.useState('')
  const [imagesArray, setimagesArray] = React.useState([])
  const [intlConfig, setIntlConfig] = useState({
    country: 'Libra',
    locale: 'en-GB',
    currency: 'GBP',
  })
  const [value, setValue] = useState(0.0)
  const [rawValue, setRawValue] = useState(0.0)

  const handleIntlSelect = (event) => {
    const config = options[Number(event.target.value)]
    if (config) {
      setIntlConfig(config)
    }
    config.currency == 'BRL'
      ? setCurrency('R$')
      : config.currency == 'USD'
      ? setCurrency('$')
      : config.currency == 'EUR'
      ? setCurrency('€')
      : setCurrency('£')
  }

  useEffect(() => {
    async function fetchData() {
      await Promise.all([getUser(), getAdCategory()])
    }
    fetchData()
  }, [])

  useEffect(() => {
    idCategory && getAdSubCategory(idCategory)
  }, [idCategory])

  const getUser = () => {
    let info = JSON.parse(window.sessionStorage.getItem('userData'))
    if (info === '' || info === undefined || info === null) {
      window.location.href = 'http://dash.jaspiapp.com/'
    } else {
      setUserInfo(info)
      setToken(() => info.token)
    }
  }

  const getAdCategory = async () => {
    await api
      .get(`/adCategory/`)
      .then(function (response) {
        setcatProperty(() => response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  const getAdSubCategory = async (idCategory) => {
    await api
      .get(`/adSubCategory/?adCategory=${idCategory}`)
      .then(function (response) {
        setcatSubProperty(() => response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleFileInput = (e) => {
    // handle validations
    const file = e.target.files
    if (file.size > 1024000) {
      toast.error('File size cannot exceed more than 1MB')
    } else {
      setimagesArray(e.target.files)
      // console.log(file)
    }
  }
  const validateValue = (value) => {
    const rawValue =
      value === undefined ? 'undefined' : value.replace(',', '.') * 1
    setprice(rawValue)
    setRawValue(rawValue)
  }

  const selectPlan = async (e) => {
    setShowDefault(true)
    e.preventDefault()
  }

  const publishAd = (plan) => {
    let info = JSON.parse(window.sessionStorage.getItem('userData'))
    if (localization === '') {
      toast.error('Please fill in the address field.')
      setShowDefault(false)
    } else if (!price) {
      toast.error('Please write a price')
      setShowDefault(false)
    } else if (!title) {
      toast.error('Please write a title')
      setShowDefault(false)
    } else if (!idCategory) {
      toast.error('Please select a Category')
      setShowDefault(false)
    } else if (
      imagesArray === null ||
      imagesArray === undefined ||
      imagesArray === ''
    ) {
      toast.error('Please select at least one image for your ad!')
      setShowDefault(false)
    } else {
      var formData = new FormData()
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: 'Bearer ' + info.token,
        },
      }
      const images = imagesArray
      for (const image of imagesArray) {
        formData.append('file', image)
      }
      // Ad Fields
      formData.append('title', title)
      formData.append('price', price)
      formData.append('currency', currency)
      formData.append('ecommerce', ecommerce)
      formData.append('whatsapp', whatsapp)
      formData.append('adCategory', idCategory)
      idSubCategory && formData.append('adSubCategory', idSubCategory)
      formData.append('description', description)
      formData.append('localization[description]', localization)
      formData.append('localization[latitude]', localizationLat)
      formData.append('localization[longitude]', localizationLng)
      formData.append('localization[country]', localizationCountry)
      formData.append('plan', plan)
      formData.append('status', plan === 'free' ? 'active' : 'inative')

      api
        .post(`/announcement`, formData, config)
        .then((response) => {
          if (plan === 'free') {
            toast.success('Your ad has been published successfully!')
            window.location.href = `./#/announcements?name=announcement`
          } else {
            api
              .post(
                `/create-payment-intent?skuAd=${response.data._id}&category=${category}&plan=${plan}&name=${title}`
              )
              .then((response) => {
                window.location = response.data.url
              })
          }
        })
        .catch((err) => {
          setShowDefault(false)
          toast.error(err.response.data.message)
          console.log(err.response)
        })
    }
  }
  return (
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Toaster
        position="top-right"
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 5000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
      <Card.Body className="p-5">
        <Form onSubmit={selectPlan}>
          <Row className="justify-content-center">
            <Col xs={12} md={12} className="ps-0 ms--2">
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name={'title'}
                  onInput={(e) => settitle(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-start">
            <Col xs={3} md={2} className="ps-0 ms--2">
              <Form.Label htmlFor="intlConfigSelect">
                Select your currency
              </Form.Label>
              <select
                className="form-control"
                id="intlConfigSelect"
                onChange={handleIntlSelect}
              >
                {options.map((config, i) => {
                  if (config) {
                    const { locale, currency, country } = config
                    return (
                      <option key={`${locale}${currency}`} value={i}>
                        {country}
                      </option>
                    )
                  }
                })}
              </select>
            </Col>
            <Col xs={9} md={2} className="ps-0 ms--2">
              <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>

                <CurrencyInput
                  className="form-control"
                  required
                  name={'price'}
                  onValueChange={validateValue}
                  intlConfig={intlConfig}
                  decimalsLimit={6}
                  value={price}
                  step={1}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4} className="ps-0 ms--2">
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  name={'switchValueCategory'}
                  onChange={(e) => setidCategory(e.target.value)}
                >
                  <option>Select...</option>
                  {catProperty.map((item, index) => {
                    return (
                      <option key={item._id} value={item._id}>
                        {item.title.en}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} md={4} className="ps-0 ms--2">
              <Form.Group className="mb-3">
                <Form.Label>Sub Category</Form.Label>
                <Form.Select
                  name={'switchValueSubCategory'}
                  onChange={(e) => setidSubCategory(e.target.value)}
                >
                  <option>Select...</option>
                  {catSubProperty.length ? (
                    <>
                      {catSubProperty.map((item, index) => {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.title.en}
                          </option>
                        )
                      })}
                    </>
                  ) : (
                    <></>
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-start">
            <Col xs={6} md={12} className="ps-0 ms--2">
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="4"
                  name={'description'}
                  placeholder="Enter your description..."
                  onInput={(e) => setdescription(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-start">
            <Col xs={12} md={12} className="ps-0 ms--2">
              <Form.Group className="mb-3">
                <Form.Label>Localization</Form.Label>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyDnl9f2_NFV8wd-aPBR6KxCmpUyuSRU3Qs"
                  name={'localization'}
                  placeholder=""
                  selectProps={{
                    onChange: (value) =>
                      geocodeByAddress(value.value.description)
                        .then((results) => getLatLng(results[0]))
                        .then(
                          ({ lat, lng }) => (
                            setlocalization(value.value.description),
                            setlocalizationLat(lat),
                            setlocalizationLng(lng),
                            setlocalizationCountry(
                              value.value?.terms.pop().value
                            )
                          )
                        ),
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={12} className="ps-0 ms--2">
              <Form.Group className="mb-3">
                <Form.Label>Link to sell (e-commerce)</Form.Label>
                <Form.Control
                  type="text"
                  name={'title'}
                  placeholder={'https://mystore.com/'}
                  onInput={(e) => setEcommerce(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="justify-content-start">
            <Col xs={12} md={12} className="ps-0 ms--2">
              <h2 className="mt-5">Contact</h2>
            </Col>
            <Col xs={12} md={4} className="ps-0 ms--2">
              <Form.Group className="mb-3">
                <Form.Label>Whatsapp</Form.Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={whatsapp}
                  onChange={(e) => setWhatsapp(e)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={12} className="ps-0 ms--2">
              <h2 className="mt-5">Images</h2>
            </Col>
            <Col xs={12} md={12} className="ps-0 ms--2">
              <Form.Control type="file" multiple onChange={handleFileInput} />
            </Col>
            <Col xs={12} md={12} className="ps-0 ms--2 mt-5">
              <Button
                type="submit"
                size="lg"
                color="#B79800"
                className="w-100 py-3"
              >
                Publish
              </Button>
            </Col>
          </Row>
        </Form>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between"></Card.Footer>
      </Card.Body>
      <Modal
        as={Modal.Dialog}
        fullscreen={true}
        centered
        show={showDefault}
        onHide={handleClose}
      >
        <Modal.Body>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleClose}
            className="float-end"
          />
          <h4 className="text-center">SELECT A PLAN</h4>
          <hr />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 text-center">
                <Card>
                  <Card.Header as="h4">FREE</Card.Header>
                  <Card.Body>
                    <Card.Title>£ 0.00</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>Unprominent ad</li>
                        <li>Display only one photo</li>
                        <li>Contact via chat</li>
                        <li>Ad expires in 7 days</li>
                      </ul>
                    </Card.Text>
                    <Button
                      variant="outline-dark"
                      size="lg"
                      onClick={() => publishAd('free')}
                    >
                      Select free plan
                    </Button>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-lg-6 text-center">
                <Card>
                  <Card.Header as="h4">GOLD</Card.Header>
                  <Card.Body>
                    <Card.Title>£ 5.97</Card.Title>
                    <Card.Text>
                      <ul>
                        <li>Featured ad</li>
                        <li>Display up to 10 photos</li>
                        <li>Contact via email and phone</li>
                        <li>Your brand logo in the ad</li>
                        <li>Ad expires in 15 days</li>
                      </ul>
                    </Card.Text>
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => publishAd('gold7')}
                    >
                      Select GOLD plan
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Card>
  )
}

export default ({ name }) => {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>JASPI</Breadcrumb.Item>
            <Breadcrumb.Item active>Create Ad</Breadcrumb.Item>
          </Breadcrumb>
          <h4>New Ad</h4>
        </div>
      </div>
      <AnnouncementAdd />
    </>
  )
}
