import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import toast, { Toaster } from 'react-hot-toast'
import {
  faAngleLeft,
  faEnvelope,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faGithub,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  Alert,
  InputGroup,
} from '@themesberg/react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

import { Routes } from '../../routes'
import BgImage from '../../assets/img/bgLogin.jpg'
import api from '../../services/api'

import { app } from '../../services/firebase'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

function Signin() {
  const state = { email: '', password: '', loading: false }

  const handleChange = (event) => {
    let nam = event._dispatchInstances.memoizedProps.name
    let val = event.target.value
    ;[nam] == 'email' ? (state.email = val) : (state.password = val)
  }
  const auth = getAuth(app)

  let history = useHistory()
  async function handleLogin(event) {
    state.loading = true
    event.preventDefault()

    const loginMongo = async (email) => {
      let loginUser = {
        email: email,
        password: process.env.REACT_APP_PASSWORDUSER,
      }
      const resp = await api
        .post('login/', loginUser)
        .then(async (response) => {
          const user = response.data
          if (response.data.error) {
            return toast.error(response.data.message)
          }
          state.loading = false
          window.sessionStorage.setItem('userData', JSON.stringify(user))
          history.push('/announcements?name=announcement')
        })
        .catch((err) => {
          state.loading = false
          toast.error(err.message)
        })
    }

    await signInWithEmailAndPassword(auth, state.email, state.password)
      .then(async (userCredential) => {
        const user = userCredential.user
        await loginMongo(state.email)
      })
      .catch((err) => {
        state.loading = false
        toast.error(err.message)
      })
      .finally(() => {
        state.loading = false
      })
  }
  return (
    <main
      style={{
        backgroundImage: `url(${BgImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center right',
        height: '100vh',
      }}
    >
      <Toaster
        position="top-right"
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 5000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
      <section className="d-flex align-items-center mb-lg-5 h-100">
        <Container>
          <p className="text-center">
            <Card.Link
              as={Link}
              to={Routes.DashboardOverview.path}
              className="text-white"
            >
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to
              homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form className="mt-1" onSubmit={handleLogin}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control
                        autoFocus
                        required
                        type="email"
                        name={'email'}
                        placeholder="example@company.com"
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="password"
                          name={'password'}
                          placeholder="Password"
                          onChange={handleChange}
                        />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Card.Link
                        as={Link}
                        to={Routes.ForgotPassword.path}
                        className="small text-end"
                      >
                        Lost password?
                      </Card.Link>
                    </div>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100">
                    {state.loading == true ? 'Loading...' : 'Sign in'}
                  </Button>
                </Form>
                {/* <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">or login with</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-facebook me-2"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pill text-twitter me-2"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </Button>
                  <Button
                    variant="outline-light"
                    className="btn-icon-only btn-pil text-dark"
                  >
                    <FontAwesomeIcon icon={faGithub} />
                  </Button>
                </div> */}
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link
                      as={Link}
                      to={Routes.Signup.path}
                      className="fw-bold"
                    >
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}
export default Signin
