import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import {
  Col,
  Row,
  Form,
  Button,
  Breadcrumb,
  Card,
} from '@themesberg/react-bootstrap'
import Cards from 'react-credit-cards'
import 'react-credit-cards/es/styles-compiled.css'
import api from '../../services/api'
import toast, { Toaster } from 'react-hot-toast'

export const Billing = ({ name }) => {
  const [token, setToken] = React.useState('')
  const [userInfo, setUserInfo] = React.useState('')
  const [cvc, setcvc] = useState('')
  const [expiry, setexpiry] = useState('')
  const [focus, setfocus] = useState('')
  const [cardname, setcardname] = useState('')
  const [number, setnumber] = useState('')
  const [zipcode, setzipcode] = useState('')
  const [havecard, sethavecard] = useState(false)

  useEffect(() => {
    let info = JSON.parse(window.sessionStorage.getItem('userData'))
    if (info == '' || info == undefined || info == null) {
      window.location.href = 'http://dash.jaspiapp.com/'
    } else {
      let config = {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + info.token,
        },
      }
      setUserInfo(info)
      setToken(info.token)
      api
        .get('/creditcard', config)
        .then((response) => {
          // toast.success('Your card has been saved!')
          if (response.data) {
            api
              .get(`/creditcard/${response.data._id}`, config)
              .then((response) => {
                console.log('Decodificação do Cartão', response.data)
                let hideNum = []
                let trata = ''
                for (let i = 0; i < response.data.length; i++) {
                  if (i < response.data.length - 4) {
                    hideNum.push('0')
                  } else {
                    hideNum.push(response.data[i])
                  }
                }
                trata = hideNum.join('')
                setnumber(trata)
              })
              .catch((err) => {
                toast.error(err.response.data.message)
                console.log(err.response)
              })

            setcardname(response.data.name)
            setexpiry(response.data.validid)
            setzipcode(response.data.cep)
            sethavecard(true)
          } else {
            console.log('Tem cartão?', 'NÃO')
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message)
          console.log(err.response)
        })
    }
  }, [token])

  const handleInputFocus = (e) => {
    setfocus(e.target.name)
    setfocus((state) => {
      return state
    })
  }

  const saveCard = (e) => {
    e.preventDefault()
    const config = {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }
    let infoCard = {
      name: cardname,
      numbercard: number,
      validid: expiry,
      cvc: '',
      cep: zipcode,
    }

    if (havecard) {
      api
        .put('/creditcard', infoCard, config)
        .then((response) => {
          toast.success('Your payment method has been updated!')
          sethavecard(true)
        })
        .catch((err) => {
          toast.error(err.response.data.message)
          console.log(err.response)
        })
    } else {
      api
        .post('/creditcard', infoCard, config)
        .then((response) => {
          toast.success('Your card has been saved!')
          sethavecard(true)
        })
        .catch((err) => {
          toast.error(err.response.data.message)
          console.log(err.response)
        })
    }
  }

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          className: '',
          duration: 5000,
          style: {
            background: '#363636',
            color: '#fff',
          },
          success: {
            duration: 5000,
            theme: {
              primary: 'green',
              secondary: 'black',
            },
          },
        }}
      />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb
            className="d-none d-md-inline-block"
            listProps={{ className: 'breadcrumb-dark breadcrumb-transparent' }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item>JASPI</Breadcrumb.Item>
            <Breadcrumb.Item active>Payment Method</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Credit card</h4>
        </div>
      </div>
      <Row className="justify-content-center">
        <Col xs={12} xl={8}>
          <Card border="light" className="bg-white shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">Billing Information</h5>
              <div id="PaymentForm">
                <Cards
                  cvc={cvc}
                  expiry={expiry}
                  focused={focus}
                  name={cardname}
                  number={number}
                />
              </div>
              <Form onSubmit={saveCard}>
                <Row>
                  <Col md={12} className="mb-3">
                    <Form.Group id="">
                      <Form.Label>Card Number *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="number"
                        placeholder={havecard ? number : 'Card Number'}
                        onChange={(value) => setnumber(value.target.value)}
                        onFocus={handleInputFocus}
                        maxLength={16}
                        minLength={16}
                        disabled={havecard ? true : false}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-3">
                    <Form.Group id="">
                      <Form.Label>Name *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="name"
                        placeholder={havecard ? cardname : 'Name'}
                        onChange={(value) => setcardname(value.target.value)}
                        onFocus={handleInputFocus}
                        disabled={havecard ? true : false}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="">
                      <Form.Label>Valid Thru *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="expiry"
                        placeholder={havecard ? expiry : 'Valid Thru'}
                        onChange={(value) => setexpiry(value.target.value)}
                        onFocus={handleInputFocus}
                        maxLength={4}
                        minLength={4}
                        disabled={havecard ? true : false}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="">
                      <Form.Label>CVC *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="cvc"
                        placeholder={havecard ? cvc : 'CVC'}
                        onChange={(value) => setcvc(value.target.value)}
                        onFocus={handleInputFocus}
                        maxLength={4}
                        minLength={3}
                        disabled={havecard ? true : false}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="">
                      <Form.Label>Postal code *</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="zipcode"
                        placeholder={havecard ? zipcode : 'Postal code'}
                        onChange={(value) => setzipcode(value.target.value)}
                        minLength={5}
                        disabled={havecard ? true : false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="mt-3 d-flex justify-content-end">
                  <div className="mx-1">
                    {havecard ? (
                      <Button
                        variant="light"
                        onClick={() => sethavecard(false)}
                      >
                        Edit
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mx-1">
                    <Button variant="success" type="submit">
                      Save card
                    </Button>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default () => <Billing />
